import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Sidebar } from 'semantic-ui-react';
import { connect } from 'react-redux';

import MenuBar from '../MenuBar';
import NavSidebar from '../NavSidebar';
import Dashboard from '../Dashboard/Dashboard';
import ClientEdit from '../Clients/ClientEdit';
import ClientNew from '../Clients/ClientNew';
import IncidentEdit from '../Incident/IncidentEdit';
import IncidentNew from '../Incident/IncidentNew';
import IncidentViewer from '../Incident/IncidentFormViewer/IncidentView';
import ViewUser from '../ViewUser';
import MyForms from '../MyForms';
import SafeSpaceNew from '../SafeSpace/SafeSpaceNew';
import SafeSpace from '../SafeSpace/SafeSpace';
import Profile from '../Profile/Profile';
import Admin from '../Admin';
import Reports from '../Reports';
import ProgramTotals from '../Reports/ProgramTotals';
import Quarterly from '../Reports/Quarterly';
import SocialServices from '../SocialServices/SocialServices';
import NoMatch from '../NoMatch';
import About from '../About';
import RegisterVolunteerHours from "../Volunteers/RegisterVolunteerHours";

class MainContainer extends Component {
    constructor(props) {
        super(props);

        // console.log(props);

        this.state = {
            visible: false,
        };
    }

    toggleVisibility = () => this.setState({ visible: !this.state.visible });

    render() {
        return (
            <div>
                <MenuBar visibilityToggle={this.toggleVisibility} />
                <Sidebar.Pushable attached='bottom'>
                    <NavSidebar
                        visible={this.state.visible}
                        visibilityToggle={this.toggleVisibility}
                    />
                    <Sidebar.Pusher>
                        <Switch>
                            <Route exact path='/' component={Dashboard} />
                            <Route component={About} path='/about' />
                            <Route component={Dashboard} path='/dashboard' />
                            <Route
                                component={ClientEdit}
                                path='/client/edit/'
                            />
                            <Route component={ClientNew} path='/client/new' />
                            <Route
                                component={IncidentEdit}
                                path='/incident/edit/'
                            />
                            <Route
                                component={IncidentNew}
                                path='/incident/new/'
                            />
                            <Route
                                component={IncidentViewer}
                                path='/incident/view/'
                            />
                            <Route
                                component={ViewUser}
                                path={'/user/view/:userId'}
                            />
                            <Route component={MyForms} path='/myforms' />
                            <Route
                                component={SafeSpaceNew}
                                path='/safebase/new'
                            />
                            <Route
                                exact
                                component={SafeSpace}
                                path='/safebase'
                            />
                            <Route component={Profile} path='/profile' />
                            <Route component={Admin} path='/admin' />
                            <Route exact component={Reports} path='/reports' />
                            <Route exact component={RegisterVolunteerHours} path='/volunteerHours' />
                            <Route
                                component={ProgramTotals}
                                path='/reports/programTotals'
                            />
                            <Route
                                component={Quarterly}
                                path='/reports/quarterly'
                            />
                            <Route
                                component={SocialServices}
                                path='/socialServices'
                            />
                            <Route component={NoMatch} />
                        </Switch>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile,
    };
};

export default withRouter(connect(mapStateToProps)(MainContainer));
